import { datadogRum } from '@datadog/browser-rum';

export default ({ $config, isDev }) => {
  if (isDev) {
    return;
  }
  datadogRum.init({
    applicationId: '00be1abb-3f9b-4366-a807-af0465827e44',
    clientToken: 'pub313101d1b90da267292dd1f7868d3f1b',
    site: 'datadoghq.eu',
    service: 'lms-frontend',
    env: $config.datadogEnv,
    sessionSampleRate: 100,
    trackUserInteractions: true,
  });
  datadogRum.startSessionReplayRecording();
};
