export const gtmEvents = Object.freeze({
  login: 'login',
  logout: 'logout',
  elementClick: 'ButtonClicks',
  lmsBannerClick: 'lms_banner_click',
  wrappedBannerClick: 'wrapped_banner_click',
  wrappedShareButtonClick: 'wrapped_share_button_click',
  experimentViewed: 'experiment_viewed',
  onboardingEscapeMod: 'onboarding_escape_mod',
  onboardingEscapeBack: 'onboarding_escape_back',
  onboardingShowEscapeMod: 'onboarding_show_escape_mod',
  onboardingShowStep: 'onboarding_show_step',
  lmsChangeVideoplayer: 'lms_change_videoplayer',
});
