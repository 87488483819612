export default ({ store }, inject) => {
  inject('device', {
    get isSmallScreen() {
      return store.getters['responsive/isSmallScreen'];
    },
    get isPhoneScreen() {
      return store.getters['responsive/isPhoneScreen'];
    },
    get isTabletScreen() {
      return store.getters['responsive/isTabletScreen'];
    },
    get isDesktopScreen() {
      return store.getters['responsive/isDesktopScreen'];
    },
    get isLargeScreen() {
      return store.getters['responsive/isLargeScreen'];
    },
    get windowWidth() {
      return store.state.responsive.windowWidth;
    },
  });
};
