<template>
  <div :class="$style.root">
    <div :class="$style.toolbar">
      <slot name="toolbar">
        <MonthSelector
          :selected-date="selectedDate"
          :class="$style.monthSelector"
          :extreme-date-to="extremeDateTo"
          :extreme-date-from="extremeDateFrom"
          @change="changeMonth"
        />
        <MonthCalendarDateSelector
          :selected-date="selectedDate"
          :class="$style.dateSelector"
          @date-selected="onDateChange"
        />
        <slot name="toolbarItem"></slot>
      </slot>
    </div>
    <slot name="beforeCalendar"></slot>
    <MonthCalendarWeekdays
      :class="$style.weekdays"
      :label-position="weekdaysLabelPosition"
    />
    <div :class="$style.content">
      <ol v-resize="onCalendarResize" :class="$style.daysGrid">
        <MonthCalendarDayItem
          v-for="day in days"
          :key="day.date"
          :day="day"
          :is-today="isToday(day.date)"
          :is-interactive="isDayItemInteractive(day.date)"
          :class="$style.daysGridItem"
          :hide-number="hideDayItemNumber"
          @open="$emit('day-click', day.date)"
        >
          <slot name="dayItem" :date-string="day.date"></slot>
        </MonthCalendarDayItem>
      </ol>
      <WLoading :class="$style.loading" :show="isLoading" overlay />
    </div>
  </div>
</template>

<script>
import { WLoading } from '@WebiumTeam/ui_kit';
import { isSameDay, setMonth, setYear } from '~/services/datetime';
import { getMonthCalendarDays, getTodayDate } from '~/services/timetable';
import MonthCalendarDateSelector from './month-calendar-date-selector.vue';
import MonthCalendarDayItem from './month-calendar-day-item.vue';
import MonthCalendarWeekdays from './month-calendar-weekdays.vue';
import MonthSelector from './month-selector.vue';

export default {
  components: {
    WLoading,
    MonthCalendarDayItem,
    MonthCalendarDateSelector,
    MonthCalendarWeekdays,
    MonthSelector,
  },
  provide() {
    return {
      calendarSizes: this.calendarSizes,
    };
  },
  props: {
    externalSelectedDate: {
      type: [Object, String, Date],
      required: false,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    extremeDateTo: {
      type: Date,
      default: undefined,
    },
    extremeDateFrom: {
      type: Date,
      default: undefined,
    },
    hideDayItemNumber: {
      type: Boolean,
      default: false,
    },
    isDayItemInteractive: {
      type: Function,
      default: () => false,
    },
    weekdaysLabelPosition: {
      type: String,
      default: '',
      required: false,
    },
  },
  data() {
    return {
      selectedDate: getTodayDate(),
      calendarSizes: { width: undefined, height: undefined },
    };
  },
  computed: {
    days() {
      return getMonthCalendarDays({
        selectedDate: this.selectedDate,
      });
    },
    today() {
      return getTodayDate();
    },
  },
  watch: {
    externalSelectedDate: {
      handler(date) {
        this.selectedDate = date;
      },
    },
  },
  mounted() {
    this.calculateInitialCalendarSizes();
  },
  methods: {
    onDateChange(newSelectedDate) {
      this.selectedDate = newSelectedDate;
      this.$emit('date-change', newSelectedDate);
    },
    isToday(date) {
      return isSameDay(date, this.today);
    },
    calculateInitialCalendarSizes() {
      const rect = this.$el.getBoundingClientRect();
      this.calendarSizes.width = rect.width;
      this.calendarSizes.height = rect.height;
    },
    onCalendarResize({ width, height }) {
      this.calendarSizes.width = width;
      this.calendarSizes.height = height;
    },
    changeMonth(dateString) {
      const [newYear, newMonth] = dateString.split('.');

      let newSelectedDate = setMonth({
        value: this.selectedDate,
        month: newMonth - 1,
      });
      newSelectedDate = setYear({ value: newSelectedDate, year: newYear });
      this.selectedDate = newSelectedDate;
      this.$emit('date-change', newSelectedDate);
    },
  },
};
</script>

<style lang="postcss" module>
.root {
  --border-width: 2px;
  --border-color: var(--grey-300);

  position: relative;

  & .loading {
    border-radius: 8px;
  }
}

.toolbar {
  display: flex;
  align-items: center;
  min-height: 36px;
  margin-bottom: 16px;

  & > * {
    height: inherit;
  }
}

.content {
  position: relative;
  padding: var(--border-width);
}

.monthSelector {
  max-width: 168px;
}

.dateSelector {
  margin: 0 12px;
}

.weekdays {
  margin-bottom: 8px;
}

.daysGrid {
  position: relative;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  height: 100%;
}

.daysGridItem {
  min-width: 0; /* prevent content from expanding the cell */
  margin-top: calc(var(--border-width) * -1);
  margin-left: calc(var(--border-width) * -1);
  border: var(--border-width) solid var(--border-color);
  transition: var(--default-transition);

  &:first-of-type {
    border-top-left-radius: var(--standard-border-radius);
  }

  &:nth-of-type(7) {
    border-top-right-radius: var(--standard-border-radius);
  }

  &:nth-last-of-type(7) {
    border-bottom-left-radius: var(--standard-border-radius);
  }

  &:last-of-type {
    border-bottom-right-radius: var(--standard-border-radius);
  }
}

@media (--small-vp) {
  .dateSelector {
    margin-left: 8px;
  }

  .toolbar {
    height: auto;
  }

  .weekdays {
    width: calc(100% + 4px * 2);
    margin: 0 -4px;
    margin-bottom: 8px;
  }

  .daysGrid {
    width: calc(100% + 4px * 2);
    margin: 0 -4px;
  }
}
</style>
